import * as React from "react";
const SalesStaticsIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
		<path
			stroke="white"
			strokeWidth={1.5}
			d="M12 3a2 2 0 0 1 2 2v14a2 2 0 1 1-4 0V5a2 2 0 0 1 2-2ZM4 12a2 2 0 0 1 2 2v5a2 2 0 1 1-4 0v-5a2 2 0 0 1 2-2ZM20 8a2 2 0 0 1 2 2v9a2 2 0 1 1-4 0v-9a2 2 0 0 1 2-2Z"
		/>
	</svg>
);
export default SalesStaticsIcon;

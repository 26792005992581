import { Modal, Input,  Button } from "antd";
import React, { useState,  useRef } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../services/http";
import SuccessModal from "../Modal/SuccessModal";
import ErrorModal from "../Modal/ErrorModal";
const CreteItemModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation();
	const successRef = useRef();
	const errorRef = useRef();

	const [loading, setLoading] = useState(false);
	const [width, setWidth] = useState("");
	const [height, setHeight] = useState("");
	const [itemName, setItemName] = useState("");


	const postData = async () => {
		setLoading(true);

		try {
			await http.post("api/items/add-item", {
				itemName,
				itemType:"I",
				itemsGroupCode: "103",
				purchaseUnitWidth: width,
				purchaseUnitHeight: height,
			});
			successRef.current.open(t("sucCreated"));
			onClose();
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message);
			onClose();
		} finally {
			setLoading(false);
		}
	};

	if (!data) return null;

	return (
		<Modal
			title={<h1 className="font-nunito  font-extrabold text-[#000000]">{t("AddProduct")}</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="40%"
			style={{ height: "70%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("itemsName")}</p>
					<Input
						type="text"
						onChange={(e) => {
							setItemName(e.target.value);
						}}
						className="flex h-10 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
					/>
				</div>
				{/* <div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("productType")}</p>
					<Select
						value={itemType}
						onChange={(value) => setItemType(value)}
						className="w-[200px]"
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						<Select.Option key={1} value={"L"}>
							{"Работа"}
						</Select.Option>
						<Select.Option key={2} value={"I"}>
							{"Товар"}
						</Select.Option>
					</Select>
				</div>
				<div className="mt-4 flex flex-col gap-3 sm:mt-5">
					<p>{t("productGroup")}</p>
					<Select
						value={itemsGroup}
						onChange={(value) => setItemsGroup(value)}
						className="w-[200px]"
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{Idata.map((supplier) => (
							<Select.Option key={supplier.itmsGrpCod} value={supplier.itmsGrpCod}>
								{supplier.itmsGrpNam}
							</Select.Option>
						))}
					</Select>
				</div> */}
				<div className="flex items-center gap-10">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5">
						<p>{`${t("width")} (${t("mm")})`}</p>
						<Input
							type="text"
							onChange={(e) => {
								setWidth(e.target.value);
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5">
						<p>{`${t("height")} (${t("mm")})`}</p>
						<Input
							type="text"
							onChange={(e) => {
								setHeight(e.target.value);
							}}
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose();
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t("cancel")}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t("add")}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r;
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r;
				}}
			/>
		</Modal>
	);
};

export default CreteItemModal;

import * as React from "react";
const SalesIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
		<path
			stroke="#fff"
			d="M18.75 8.75h-7.5a2.5 2.5 0 0 0-2.5 2.5v7.5a2.5 2.5 0 0 0 2.5 2.5h7.5a2.5 2.5 0 0 0 2.5-2.5v-7.5a2.5 2.5 0 0 0-2.5-2.5ZM16.25 12.5h-2.5"
		/>
		<path
			stroke="#fff"
			d="M27.5 15c0 6.904-5.596 12.5-12.5 12.5-4.09 0-7.72-1.964-10-5M2.5 15C2.5 8.096 8.096 2.5 15 2.5c4.09 0 7.72 1.964 10 5M5 22.5l-1.25 3.75M25 7.5l1.25-3.75"
		/>
	</svg>
);
export default SalesIcon;

import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/Layout/index";
import { useQuery } from '@tanstack/react-query';
import { fetchWarehouse,fetchReports } from "../../../utils/fetchData";

const Reports = () => {
	const { t } = useTranslation();


	const { data :warehouseData= [] } = useQuery({
		queryKey: ['warehouses'],
		queryFn: fetchWarehouse,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const { data :reportsData= [], isLoading } = useQuery({
		queryKey: ['reports'],
		queryFn: fetchReports,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});


	const columns = [
		{
			title: t("item"),
			dataIndex: "itemName",
			key: "itemName",
			// width: 200,
			fixed: "left",
			render: (text) => <b>{text}</b>,
		},
		{
			title: t("available"),
			dataIndex: "available",
			key: "available",

			render: (text, record) => {
				const warehouseInfo = record.inventoryItemWhs[0];
				const onHand = warehouseInfo ? warehouseInfo.available : "0";
				const formattedonHand = new Intl.NumberFormat("fr-FR").format(onHand);
				return <span>{formattedonHand}</span>;
			},
		},

		{
			title: t("quantityReport"),
			dataIndex: "onHandSum",
			key: "onHandSum",

			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},

		...warehouseData.map((item) => ({
			title: item.name,
			dataIndex: item.code,
			key: item.code,
			render: (text, record) => {
				const warehouseInfo = record.inventoryItemWhs.find((w) => w.warehouseName === item.name);
				const onHand = warehouseInfo ? warehouseInfo.onHand : "0";
				const formattedonHand = new Intl.NumberFormat("fr-FR").format(onHand);
				return <span>{formattedonHand}</span>;
			},
		})),
	];

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
					{t("reportsWhs")}
				</h1>

				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

				<div className="ml-10">
					<Table
						columns={columns}
						dataSource={reportsData}
						pagination={false}
						className="mt-6 w-full sm:mt-10"
						rowKey="zakaz"
						bordered
						sticky={true}
						loading={isLoading}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default Reports;

import React, { useState, useEffect, useCallback } from "react";
import { http } from "../../../services/http";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import { columnSalesOrder } from "../../../utils/columns";
import { getToday } from "../../../utils/getDays";
import Layout from "../../../components/Layout";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewSalesOrderModal from "../../../components/Modal/viewSalesOrderModal";
import { useQuery } from '@tanstack/react-query';
import { fetchSales } from '../../../utils/fetchData';

const SalesOrder = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const role = useSelector((state) => state.main.role);
	const department = useSelector((state) => state.main.department);
	const formattedDate = getToday();


	const [currentPage, setCurrentPage] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [docNum, setDocNum] = useState("");
	const [cardName, setCardName] = useState("");
	const [slpName, setSlpName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState(formattedDate);

	const { data = [], isLoading } = useQuery({
		queryKey: ['salesOrder', currentPage, cardName, docNum, startDate, endDate,slpName,department,"api/sales/sotuv-buyurtmasi"],
		queryFn: fetchSales,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const handleRowClick = (record) => {
		setSelectedRowData(record);
		setIsModalVisible(true);
	};

	const handleStartDate = (e) => {
		setStartDate(e.target.value);
	};

	const handleEndDate = (e) => {
		setEndDate(e.target.value);
	};

	const debouncedSet = debounce((val, setState) => {
		setState(val);
	}, 500);

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum);
	};

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName);
	};
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName);
	};

	const columns = columnSalesOrder(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange
	);

	const handleAdd = () => {
		navigate("add");
	};

	return (
		<Layout>
			<Header
				title={t("SalesOrder")}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				isSticky={false}
				formattedDate={formattedDate}
				{...(role !== "Cashier" ? { onBtnClick: handleAdd } : {})}
			/>
			<ViewSalesOrderModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
		</Layout>
	);
};

export default SalesOrder;

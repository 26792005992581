import { Modal, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

const ViewMySalesModal = ({ visible, data, onClose, onClick, refetch }) => {
	const { t } = useTranslation();

	const [dueDate, setDueDate] = useState("");

	useEffect(() => {
		if (data?.docDueDate) {
			setDueDate(data?.docDueDate);
		}
	}, [data]);

	if (!data) return null;

	const columns = [
		{
			title: t("item"),
			dataIndex: "itemDescription",
			key: "itemDescription",
			width: 150,
		},

		{
			title: t("quantityN"),
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
		{
			title: t("kv.m"),
			dataIndex: "ugpCode",
			key: "ugpCode",
		},

		{
			title: t("price"),
			dataIndex: "price",
			key: "price",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText} USD</span>;
			},
		},

		{
			title: t("totalMiqdor"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText} USD</span>;
			},
		},
	];

	const tableData = data?.documentLines.map((item, index) => ({
		key: index,
		itemDescription: item.itemDescription,
		quantity: item.quantity,
		price: item.price,
		lineTotal: item.lineTotal,
		whsName: item.whsName,
		ugpCode: item.ugpCode,
	}));

	return (
		<Modal
			title={
				<h1 className="font-bold text-xl font-extrabold text-[#000000]">
					{t("order")} № {data.docNum}
				</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
			style={{ height: "80%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col gap-5 items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t("supplier")}</p>
							<Input
								type="text"
								value={data.cardName}
								className="flex  w-full items-center justify-center sm:w-[160px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t("docStatus")}</p>
							<Input
								type="text"
								value={data?.docStatus === "O" ? `${t("open")}` : `${t("closed")}`}
								className="flex  w-full items-center justify-center sm:w-[160px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("status")}</p>
							<Input
								className="flex  w-full items-center justify-center sm:w-[160px]"
							value={data?.u_AbsoluteStatus}
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p className="font-bold">{t("pManager")}</p>
							<Input
								type="text"
								value={data?.slpName}
								className="flex  w-full items-center justify-center sm:w-[160px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("currency")}</p>
							<Input
								className="flex  w-full items-center justify-center sm:w-[160px]"
								value={data.documentLines[0].currency}
								style={{ color: "black" }}
								disabled
							/>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-bold">{t("date")}</p>
							<Input
								type="text"
								value={dueDate}
								className="flex  w-full items-center justify-center sm:w-[160px]"
								style={{ color: "black" }}
								onChange={(date) => setDueDate(date)}
								disabled
							/>
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
						bordered
					/>

					<div className="w-full flex items-center justify-between">
						<div>{""}</div>
						<div className="mt-20  flex items-center gap-3 ">
							<p className="font-bold">{t("totalForDocument")}:</p>
							<Input
								type="text"
								value={`${data.docTotal} USD`}
								className="flex h-8 w-[170px] items-center justify-center sm:w-[160px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ViewMySalesModal;

import { Modal, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const ViewClientsModal = ({
	visible,
	data,
	onClose,
	onClick,
	finish = false,
	incoming = false,
	refetch,
}) => {
	const { t } = useTranslation();

	if (!data) return null;

	console.log(data);

	const columns = [
		{
			title: t("name"),
			dataIndex: "cardName",
			key: "cardName",
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("Type"),
			dataIndex: "type",
			key: "type",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("phoneNum"),
			dataIndex: "Cellular",
			key: "Cellular",
			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("Address"),
			dataIndex: "address",
			key: "address",
		},
		{
			title: t("email"),
			dataIndex: "E_Mail",
			key: "E_Mail",
		},
		{
			title: t("passportSeries"),
			dataIndex: "U_Passport",
			key: "U_Passport",
		},
		{
			title: t("passportExpDate"),
			dataIndex: "U_PassportExpDate",
			key: "U_PassportExpDate",
		},
		{
			title: t("passportGivenBy"),
			dataIndex: "U_PassportGivenBy",
			key: "U_PassportGivenBy",
		},
		{
			title: t("tgId"),
			dataIndex: "u_TgId",
			key: "u_TgId",
		},
	];

	const tableData = [
		{
			cardName: data.cardName,
			Cellular: data.Cellular,
			address: data.address,
			E_Mail: data.E_Mail,
			U_Passport: data.U_Passport,
			U_PassportExpDate: data.U_PassportExpDate,
			U_PassportGivenBy: data.U_PassportGivenBy,
			type: data.type,
			u_TgId: data.u_TgId,
		},
	];

	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
			// style={{ height: "100%" }}
			// bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4">
				<div className="w-full mt-10 mb-10">
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1  border-black"
						scroll={{ x: "100%" }}
						bordered
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ViewClientsModal;

import { Modal, Input, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
const ViewItemsModal = ({ visible, data, onClose, onClick, finish = false }) => {
	const { t } = useTranslation();

	if (!data) return null;

	const columns = [
		{
			title: t("whs"),
			dataIndex: "whsName",
			key: "whsName",
			width: 150,
		},
		{
			title: t("quantityN"),
			dataIndex: "onHand",
			key: "onHand",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
		{
			title: t("ordered"),
			dataIndex: "onOrder",
			key: "onOrder",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
		{
			title: t("general"),
			dataIndex: "available",
			key: "available",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
	];

	const tableData = data?.inventoryItemWhs.map((item, index) => ({
		key: index,
		whsName: item.whsName,
		onHand: item.onHand,
		onOrder: item.onOrder,
		available: item.available,
	}));

	return (
		<Modal
			title={<h1 className="font-nunito text-xl font-extrabold text-[#000000]">Карта товара</h1>}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: "80%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-5">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("itemName")}</p>
							<Input
								type="text"
								value={data.itemName}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("productType")}</p>
							<Input
								value={data.itemType === "I" ? "Товар" : data.itemType === "L" ? "Работа" : ""}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("productGroup")}</p>
							<Input
								value={data.itemGroup}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>

					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{`${t("width")} (${t("mm")})`}</p>
							<Input
								type="text"
								value={data.width}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{`${t("height")} (${t("mm")})`}</p>
							<Input
								value={data.height}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>

					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
						bordered
					/>

					{/* <div className="w-full flex items-center justify-between">
						<div>{""}</div>
						<div className="mt-20  flex items-center gap-3 ">
							<p className="font-nunito">Всего по документу:</p>
							<Input
								type="text"
								value={`${data.BWdth1} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-20  flex items-center gap-3 ">
							<p className="font-nunito">Всего по документу:</p>
							<Input
								type="text"
								value={`${data.BHeight1} USD`}
								className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div> */}
				</div>
			</div>
		</Modal>
	);
};

export default ViewItemsModal;

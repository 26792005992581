import React, { memo, useEffect, useState } from "react";
import ErrorModalStyle from "./ErrorModalStyle";
import ErrorImage from "../../../assets/images/error.png";
import Modal from "react-modal";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		borderRadius: 15,
		border: "none",
	},
	overlay: {
		background: "#00000099",
	},
};

const ErrorModal = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState("");
	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true);
				setErrorTitle(t);
			},
			close: () => setIsOpenModal(false),
		};
		getRef(ref);
	}, []);

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<ErrorModalStyle>
				<div className="card">
					<div className="flex justify-center">
						<img src={ErrorImage} alt="error image" className="img" />
					</div>
					<h2 className="title">{errorTitle}</h2>
					<div className="centerCard">
						<button
							className="btnN"
							onClick={() => {
								onConfirm();
								setIsOpenModal(false);
							}}
						>
							{"Понятно"}
						</button>
					</div>
				</div>
			</ErrorModalStyle>
		</Modal>
	);
};

export default memo(ErrorModal);

import React, { useState, useEffect, useCallback } from "react";
import { http } from "../../../../services/http";
import { useTranslation } from "react-i18next";
import Layout from "../../../../components/Layout";
import { useSelector } from "react-redux";
import { columnBalance } from "../../../../utils/columns";
import { Table } from "antd";
import { useQuery } from '@tanstack/react-query';
import { fetchBalance } from '../../../../utils/fetchData';

const BalancePage = () => {
	const { t } = useTranslation();
	const uzsCash = useSelector((state) => state.main.uzsCash);
	const usdCash = useSelector((state) => state.main.usdCash);


	const { data = [], isLoading } = useQuery({
		queryKey: ['balance',  uzsCash, usdCash],
		queryFn: fetchBalance,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const columns = columnBalance(t, true);

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">{t("balance")}</h1>
				</div>
				<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

				<div className="m-10">
					<Table
						columns={columns}
						dataSource={data}
						pagination={false}
						bordered
						className="w-full"
						rowKey="zakaz"
						rowClassName="text-center"
						sticky={true}
						loading={isLoading}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default BalancePage;

import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { session } from "../../services/session";
import { logout } from "../../slice/mainSlice";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import Flag from "react-world-flags";
import Menubar  from '../Menu';

const { Option } = Select;



const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.main.role);
	const terminalCash = useSelector((state) => state.main.terminalCash);
  const uzsCash = useSelector((state) => state.main.uzsCash);
	const usdCash = useSelector((state) => state.main.usdCash);
 const user = useSelector((state) => state.main.user);

 const [clicked, setClicked] = useState(false)


  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const handleClick = () => {
    navigate("/auth");
    session.delete();
    dispatch(logout());
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

	return (
		<div className="flex h-full w-full overflow-y-auto" >
    <Menubar />
    <div className="h-screen w-full overflow-y-auto" onClick={() => {
      if (clicked) setClicked(false)
    }}>
    <div
     className="relative flex h-[80px] w-full items-center justify-end gap-4 bg-[#FFFFFF] pr-10 drop-shadow-xl">
      <Select
        className=" w-32 ml-5"
        defaultValue={i18n.language}
        onChange={handleLanguageChange}
        style={{ width: 120 }}
      >
        <Option value="en">
          <span className="flex items-center">
            <Flag code="GB" className="mr-2" style={{ width: 20 }} />
            EN
          </span>
        </Option>
        <Option value="ru">
          <span className="flex items-center">
            <Flag code="RU" className="mr-2" style={{ width: 20 }} />
            RU
          </span>
        </Option>
        <Option value="uzb">
          <span className="flex items-center">
            <Flag code="UZ" className="mr-2" style={{ width: 20 }} />
            UZB
          </span>
        </Option>
      </Select>

      <div
						className="flex items-center gap-3"
						onClick={(event) => {
							event.stopPropagation()
							setClicked(!clicked)
						}}
					>
						<div className="ml-10 flex h-8 w-8 items-center justify-center rounded-full bg-[#0085FF1A] text-[#0085FF]">
            {user[0]}
						</div>
						<h1 className="font-inter text-base font-medium">
							{user}
						</h1>
						{clicked && (
							<div className="absolute right-3 top-[70px] mt-4 w-full max-w-60 rounded bg-[#FFFFFF] p-4 drop-shadow-xl">
								<div className="flex flex-col gap-2">
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('dollarCash')}</span>
										<span>{usdCash}</span>
									</div>

									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('SumCash')}</span>
										<span>{uzsCash}</span>
									</div>

									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('terminalCash')}</span>
										<span>{terminalCash}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('jobTitle')}</span>
										<span>{role}</span>
									</div>
								</div>
								<button
									className="mt-4 flex w-full items-center justify-center gap-2 rounded bg-red-500 p-2 text-white"
									onClick={handleClick}
								>
									{t('logout')} <FaSignOutAlt />
								</button>
							</div>
						)}
					</div>
    </div>
    {children}
    </div>
		</div>
	);
};

export default Layout;

import { Input } from "antd";
import moment from "moment";
import ViewIcon from "../assets/icons/viewSvg";

export function column(t, getColumnSearchProps, view = true, onView, like = false, onLike) {
	const columns = [
		{
			title: t("numZakaz"),
			dataIndex: "docNum",
			key: "docNum",
			text: "center",

			// ...getColumnSearchProps("docNum"),
		},
		// {
		//   title: t("mijoz"),
		//   dataIndex: "mijoz",
		//   key: "mijoz",
		//   text: 'center',

		//   // ...getColumnSearchProps("mijoz"),
		// },

		{
			title: t("date"),
			dataIndex: "sana",
			key: "sana",
			text: "center",

			// ...getColumnSearchProps("sana"),
		},
		{
			title: t("supplier"),
			dataIndex: "mijoz",
			key: "mijoz",
			text: "center",

			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("totalPrice"),
			dataIndex: "summa",
			key: "summa",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText} ${record.currency[0]}`;
			},

			// ...getColumnSearchProps("summa"),
		},
		{
			title: t("pManager"),
			dataIndex: "slpName",
			key: "slpName",
			text: "center",
			// ...getColumnSearchProps("yaratdi"),
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
			render: (text, record) => (
				<button
					onClick={() => onView(record)}
					className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
				>
					<ViewIcon />
				</button>
			),
		},
	];

	return columns;
}

export function columnSalesOrder(t, onView, onDocNumChange, onCardNameChange, onSlpNameChange) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "docNum",
					key: "docNum",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date"),
					dataIndex: "docDueDate",
					key: "docDueDate",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("supplier")}
						onChange={(e) => onCardNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("supplier"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "docTotal",
					key: "docTotal",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText} ${record.documentLines[0].currency}`;
					},
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("pManager")}
						onChange={(e) => onSlpNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("pManager"),
					dataIndex: "slpName",
					key: "slpName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("status"),
					dataIndex: "u_AbsoluteStatus",
					key: "u_AbsoluteStatus",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 120,
					text: "center",
					render: (text, record) => (
						<button
							onClick={() => onView(record)}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<ViewIcon />
						</button>
					),
				},
			],
		},
	];

	return columns;
}
export function columnPurchaseOrder(t, onView, onDocNumChange, onCardNameChange, onSlpNameChange) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "docNum",
					key: "docNum",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date"),
					dataIndex: "docDueDate",
					key: "docDueDate",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("supplier")}
						onChange={(e) => onCardNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("supplier"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "docTotal",
					key: "docTotal",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText} ${record.documentLines[0].currency}`;
					},
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("pManager")}
						onChange={(e) => onSlpNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("pManager"),
					dataIndex: "slpName",
					key: "slpName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("status"),
					dataIndex: "u_Status",
					key: "u_Status",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 120,
					text: "center",
					render: (text, record) => (
						<button
							onClick={() => onView(record)}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<ViewIcon />
						</button>
					),
				},
			],
		},
	];

	return columns;
}

export function columnMySales(t, onView, onDocNumChange, onCardNameChange) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "docNum",
					key: "docNum",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date"),
					dataIndex: "docDueDate",
					key: "docDueDate",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("supplier")}
						onChange={(e) => onCardNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("supplier"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "docTotal",
					key: "docTotal",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("salesManager"),
					dataIndex: "slpName",
					key: "slpName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("status"),
					dataIndex: "u_AbsoluteStatus",
					key: "u_AbsoluteStatus",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 120,
					text: "center",
					render: (text, record) => (
						<button
							onClick={() => onView(record)}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<ViewIcon />
						</button>
					),
				},
			],
		},
	];

	return columns;
}

export function columnItems(t, onView, onItemCodeChange, onItemNameChange) {
	const columns = [
		// {
		// 	title: (
		// 		<div>
		// 			<Input
		// 				placeholder={t("itemDocNum")}
		// 				onChange={(e) => onItemCodeChange(e.target.value)}
		// 				style={{ width: "100%" }}
		// 			/>
		// 		</div>
		// 	),
		// 	children: [
		{
			title: t("itemDocNum"),
			dataIndex: "itemCode",
			key: "itemCode",
			text: "center",
		},
		// ],
		// },
		// {
		// 	title: (
		// 		<div>
		// 			<Input
		// 				placeholder={t("itemName")}
		// 				onChange={(e) => onItemNameChange(e.target.value)}
		// 				style={{ width: "100%" }}
		// 			/>
		// 		</div>
		// 	),
		// 	children: [
		{
			title: t("itemName"),
			dataIndex: "itemName",
			key: "itemName",
			text: "center",
			width: 150,
		},
		// ],
		// },
		// {
		// 	title: "",
		// 	children: [
				{
			title: `${t("width")} (${t("mm")})`,
			dataIndex: "width",
			key: "width",
			text: "center"},
		// ]},

			// {
			// 	title: "",
			// 	children: [
					{
			title: `${t("height")} (${t("mm")})`,
			dataIndex: "height",
			key: "height",
			text: "center",
		},
	//  ]},
	// 	{
	// 		title: "",
	// 		children: [
				{
			title: t("quantityN"),
			dataIndex: "onHandSum",
			key: "onHandSum",
			text: "center",
		},
	// ]},
		// {
		// 	title: "",
		// 	children: [
		{
			title: "",
			dataIndex: "action",
			key: "action",
			width: 120,
			text: "center",
			render: (text, record) => (
				<button
					onClick={() => onView(record)}
					className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
				>
					<ViewIcon />
				</button>
			),
		},
		// 	],
		// },
	];

	return columns;
}

export function columnSupplier(t, onView, onCardNameChange, onSeeSales) {
	const columns = [
		{
			title: "",
			children: [
				{
					title: t("supplierCardCode"),
					dataIndex: "cardCode",
					key: "cardCode",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("name")}
						onChange={(e) => onCardNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("name"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("phoneNum"),
					dataIndex: "Cellular",
					key: "Cellular",
					text: "center",
				},
			],
		},

		{
			title: "",
			children: [
				{
					title: t("debt2"),
					dataIndex: "ordersBal",
					key: "ordersBal",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 250,
					text: "center",
					render: (text, record) => (
						<div className="flex justify-center gap-5 items-center">
							{" "}
							<button
								onClick={() => onView(record)}
								className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
							>
								<ViewIcon />
							</button>
							<button
								onClick={() => onSeeSales(record.cardCode)}
								className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
							>
								{t("act-sverka")}
							</button>
						</div>
					),
				},
			],
		},
	];

	return columns;
}

export function columnClient(
	t,
	onView,
	onNameChange,
	onTypeChange,
	onPhoneNumberChange,
	onAddressChange,
	onSeeSales
) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("name")}
						onChange={(e) => onNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("name"),
					dataIndex: "cardName",
					key: "cardName",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("Type")}
						onChange={(e) => onTypeChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("Type"),
					dataIndex: "type",
					key: "type",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("phoneNum")}
						onChange={(e) => onPhoneNumberChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("phoneNum"),
					dataIndex: "Cellular",
					key: "Cellular",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("Address")}
						onChange={(e) => onAddressChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("Address"),
					dataIndex: "address",
					key: "address",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 250,
					text: "center",
					render: (text, record) => (
						<div className="flex justify-center gap-5 items-center">
							{" "}
							<button
								onClick={() => onView(record)}
								className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
							>
								<ViewIcon />
							</button>
							<button
								onClick={() => onSeeSales(record.cardCode)}
								className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
							>
								{t("act-sverka")}
							</button>
						</div>
					),
				},
			],
		},
	];

	return columns;
}

export function columnDebtors(t, onCardNAmeSearch,onSeeSales) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("name")}
						onChange={(e) => onCardNAmeSearch(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("name"),
					dataIndex: "cardName",
					key: "cardName",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("groupName"),
					dataIndex: "groupName",
					key: "groupName",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("balance"),
					dataIndex: "balance",
					key: "balance",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText} `;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("slpName"),
					dataIndex: "slpName",
					key: "slpName",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 150,
					text: "center",
					render: (text, record) => (

							<button
								onClick={() => onSeeSales(record.cardCode)}
								className="bg-[#0A4D68] text-white w-auto p-2 h-10 rounded-lg flex justify-center items-center"
							>
								{t("act-sverka")}
							</button>
					),
				},
			],
		},

	];

	return columns;
}

export function columnOutgoingPayment(t, onView, onDocNumChange, onCashAcctNameChange) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "docNum",
					key: "docNum",
					text: "center",
				},
			],
		},

		{
			title: (
				<div>
					<Input
						placeholder={t("kassa")}
						onChange={(e) => onCashAcctNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("kassa"),
					dataIndex: "cashAcctName",
					key: "cashAcctName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date2"),
					dataIndex: "incomingPaymentDocDate",
					key: "incomingPaymentDocDate",
					text: "center",
					render: (text, record) => {
						return `${moment(text).format("DD.MM.YYYY")}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "cashSum",
					key: "cashSum",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText} ${record.docCurr}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("comments"),
					dataIndex: "comments",
					key: "comments",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 120,
					text: "center",
					render: (text, record) => (
						<div className="flex justify-center gap-5 items-center">
							{" "}
							<button
								onClick={() => onView(record)}
								className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
							>
								<ViewIcon />
							</button>
						</div>
					),
				},
			],
		},
	];

	return columns;
}

export function columnIncomingPayment(
	t,
	onView,
	onDocNumChange,
	onCardNameChange,
	onCashAcctNameChange
) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "incomingPaymentDocNum",
					key: "incomingPaymentDocNum",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("Client")}
						onChange={(e) => onCardNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("Client"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: (
				<div>
					<Input
						placeholder={t("kassa")}
						onChange={(e) => onCashAcctNameChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("kassa"),
					dataIndex: "acctName",
					key: "acctName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date2"),
					dataIndex: "incomingPaymentDocDate",
					key: "incomingPaymentDocDate",
					text: "center",
					render: (text, record) => {
						return `${moment(text).format("DD.MM.YYYY")}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "cashSum",
					key: "cashSum",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText} ${record.docCurr}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("comments"),
					dataIndex: "comments",
					key: "comments",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "",
					dataIndex: "action",
					key: "action",
					width: 120,
					text: "center",
					render: (text, record) => (
						<button
							onClick={() => onView(record)}
							className="bg-[#2198c7] text-white w-[30px] h-[25px] rounded-lg flex justify-center items-center"
						>
							<ViewIcon />
						</button>
					),
				},
			],
		},
	];

	return columns;
}

export function columnBalance(t, getColumnSearchProps, view = true, onView, like = false, onLike) {
	const columns = [
		{
			title: t("acctCode"),
			dataIndex: "acctCode",
			key: "acctCode",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},
		{
			title: t("cashAcctName"),
			dataIndex: "acctName",
			key: "acctName",
			text: "center",
			// ...getColumnSearchProps("docNum"),
		},

		{
			title: t("totalPrice"),
			dataIndex: "currTotal",
			key: "currTotal",
			text: "center",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText}`;
			},
			// ...getColumnSearchProps("mijoz"),
		},
		{
			title: t("currency"),
			dataIndex: "actCurr",
			key: "actCurr",
			text: "center",

			// ...getColumnSearchProps("summa"),
		},
	];

	return columns;
}

export function columnSeeSales(t, onView, onDocNumChange, onCardNameChange, onSlpNameChange) {
	const columns = [
		{
			title: (
				<div>
					<Input
						placeholder={t("numZakaz")}
						onChange={(e) => onDocNumChange(e.target.value)}
						style={{ width: "100%" }}
					/>
				</div>
			),
			children: [
				{
					title: t("numZakaz"),
					dataIndex: "docNum",
					key: "docNum",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("docType"),
					dataIndex: "docType",
					key: "docType",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("date"),
					dataIndex: "docDate",
					key: "docDate",
					text: "center",
					render: (text, record) => {
						return `${moment(text).format("DD.MM.YYYY")}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("supplier"),
					dataIndex: "cardName",
					key: "cardName",
					text: "center",
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("totalPrice"),
					dataIndex: "docTotal",
					key: "docTotal",
					text: "center",
					render: (text, record) => {
						const formattedText = new Intl.NumberFormat("fr-FR").format(text);
						return `${formattedText}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("canceled"),
					dataIndex: "canceled",
					key: "canceled",
					text: "center",
					render: (text, record) => {
						const formattedText = text === "N" ? t("no") : t("yes");
						return `${formattedText}`;
					},
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: t("docStatus"),
					dataIndex: "docStatus",
					key: "docStatus",
					text: "center",
					render: (text, record) => {
						const formattedText = text === "O" ? t("open") : t("closed");
						return `${formattedText}`;
					},
				},
			],
		},
	];

	return columns;
}

import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/header";
import { columnSupplier } from "../../../utils/columns";
import Layout from "../../../components/Layout";
import CreateUserModal from "../../../components/Modal/createUserModal";
import ViewSuppliersModal from "../../../components/Modal/viewSuppliersModal";
import { useNavigate } from "react-router-dom";
import { fetchSuppliers } from "../../../utils/fetchData";
import { useQuery } from '@tanstack/react-query';
import { debouncedSet } from '../../../utils/debounce';

const Suppliers = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isViewModalVisible, setIsViewModalVisible] = useState(false);
	const [selectedViewRowData, setSelectedViewRowData] = useState(null);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [cardName, setCardName] = useState("");


	const { data = [], isLoading ,refetch} = useQuery({
		queryKey: ['suppliers', cardName,currentPage],
		queryFn: fetchSuppliers,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const handleRowClick = (record) => {
		setSelectedRowData(record);
		setIsModalVisible(true);
	};
	const handleViewRowClick = (record) => {
		setSelectedViewRowData(record);
		setIsViewModalVisible(true);
	};

	const handleItemNameChange = (val) => {
		debouncedSet(val, setCardName);
	};
	const handleSeeBtnClick = (cardCode) => {
		navigate(`/see-sales?cardCode=${encodeURIComponent(cardCode)}`);
	};

	const columns = columnSupplier(t, handleViewRowClick, handleItemNameChange, handleSeeBtnClick);

	return (
		<Layout>
			<Header
				title={"suppliers"}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onBtnClick={handleRowClick}
				isSticky={false}
			/>
			<CreateUserModal
				visible={isModalVisible}
				onClose={() => {
					setIsModalVisible(false);
					setCurrentPage(0);
				refetch()
				}}
			/>
			<ViewSuppliersModal
				visible={isViewModalVisible}
				data={selectedViewRowData}
				onClose={() => setIsViewModalVisible(false)}
			/>
		</Layout>
	);
};

export default Suppliers;

import { Modal, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

const ViewSuppliersModal = ({ visible, data, onClose, onClick, finish = false }) => {
	const { t } = useTranslation();

	if (!data) return null;

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">{t("productCard")}</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: "80%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-5">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("supplierName")}</p>
							<Input
								type="text"
								value={data.cardName}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("Address")} </p>
							<Input
								value={data.address}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("phoneNum")}</p>
							<Input
								value={data.Cellular}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>

					<div className="mt-10 flex flex-col items-center gap-7  sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("Saldo")}</p>
							<Input
								type="text"
								value={new Intl.NumberFormat("fr-FR").format(data.balance)}
								className="flex h-10  w-[200px] items-center justify-center "
								style={{ color: "black" }}
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ViewSuppliersModal;
